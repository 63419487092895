export const COLORS = [
  "#6b7280",
  "#d72631",
  "#1fc865",
  "#345ee9",
  "#db29e8",
  "#e3b706",
  "#d9138a",
  "#dd7734",
  "#322e2f",
  "#d9a5b3",
  "#1868ae",
  "#6d90b7",
  "#ffcce7",
  "#47b18d",
  "#81b7d2",
  "#4d5198",
];

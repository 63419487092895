import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDdvNMVnCDmNynjORrk6E7tjOlsqHkUL_U",
  authDomain: "kompad-62edb.firebaseapp.com",
  projectId: "kompad-62edb",
  storageBucket: "kompad-62edb.appspot.com",
  messagingSenderId: "595443828895",
  appId: "1:595443828895:web:1a4b01aaa77e3f6d893aaa"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const provider = new GoogleAuthProvider();
export const auth = getAuth();

export const db = getFirestore(app);
export const storage = getStorage(app);

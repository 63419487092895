interface IVarCSSLanguages {
  [key: string]: string
}
export const varLanguages: IVarCSSLanguages = {
  "--common-text-color": "Text color",
  "--common-semidark-text-color": "Semi dark text",
  "--common-dark-text-color": "Dark text",
  "--common-text-hover-color": "Hovering text",
  "--common-bg-color": "Background",
  "--common-dark-bg-color": "Dark background",
  "--common-darker-bg-color": "Darker background",
  "--common-light-bg-color": "Lighter background",
  "--common-border-hl-color": "Higlight border",
  "--common-border-light-color": "Lighter border",
  "--common-btn-bg-color": "Button background ",
  "--common-btn-bg-hover-color": "Button background hover",
  "--common-btn-bg-active-color": "Button background active",
  "--common-primary-color": "Primary color",
  "--common-primary-hover-color": "Primary hover",
  "--common-primary-text-color": "Primary text color",
  "--common-border-color": "Border color",
  "--sidebar-background-color": "Sidebar background",
  "--sidebar-text-color": "Sidebar text color",
  "--sidebar-text-color-hover": "Sidebar text hover",
  "--sidebar-title-color": "Sidebar title color",
  "--sidebar-user-setting-border-color": "Sidebar user setting border",
  "--sidebar2-background-color": "Sub-sidebar background",
  "--sidebar2-item-active-color": "Sub-sidebar item active",
  "--sidebar2-item-hover-color": "Sub-sidebar item hover",
  "--editor-text-color": "Editor text color",
  "--editor-link-text-color": "Editor link color",
  "--editor-quote-text-color": "Editor qoute color",
  "--modal-bg-color": "Modal background",
  "--modal-footer-bg-color": "Modal footer background",
  "--modal-border-color": "Modal border color",
  "--setting-bg-color": "Setting background",
  "--sign-bg-color": "Sign background",
  "--sign-form-bg-color": "Sign form background",
  "--dropdown-bg-color": "Dropdown background",
  "--dropdown-bg-hover-color": "Dropdown background hover",
  "--tag-bg-color": "Tag background",
  "--tag-text-color": "Tag text color",
  "--button-border-active-color": "Button border active",
  "--button-text-active-color": "Button text active",
}
